.msg-accordian {
  &.accordion {
    .accordion-item {
      background-color: $transparent;
      border: none;
    }
    .accordion-button {
      font-size: rem(10px);
      padding: 0 rem(8px);
      background-color: $transparent;
      box-shadow: none;
      &:after {
        display: none;
      }
      i {
        transition: all 0.2s;
        transform: rotateZ(-180deg);
      }
      &.collapsed {
        i {
          transform: rotateZ(0deg);
        }
      }
    }
    .according-header,
    .accordion-body {
      font-size: rem(10px);
    }
    .accordion-body {
      padding: rem(12px);
    }
  }
}

.custom-accordion {
  &.accordion {
    .accordion-item {
      background-color: $white;
    }
    .accordion-button {
      background-color: $white;
      color: $dark;
      padding: rem(16px);
      font-size: rem(16px);
      font-weight: $font-weight-bold;
      box-shadow: none;
      .toggle-arrow {
        position: absolute;
        right: rem(16px);
        top: 50%;
        transform: translateY(-50%) rotate(90deg);
      }
      &:after {
        display: none;
      }
      :not(.collapsed) {
        color: $dark;
      }
      &.collapsed{
        .toggle-arrow{
          transform: rotate(0) translateY(-50%);
        }
      }
    }
    .accordion-body {
      padding-top: 0;
    }
  }
}
