.table {
  td,
  th {
    border: none;
  }
  tbody {
    tr {
      border-top: 1px solid $gray-200;
    }
  }
  &.head-light {
    thead {
      th {
        background-color: rgba($gray-light100, 0.5);
        &:first-child {
          padding-left: rem(16px) !important;
        }
        &:last-child {
          padding-right: rem(16px) !important;
        }
      }
    }
    tbody {
      td {
        &.text-truncate {
          max-width: rem(120px);
          span {
            max-width: rem(168px);
          }
        }
        &:first-child {
          padding-left: rem(16px) !important;
        }
        &:last-child {
          padding-right: rem(16px) !important;
        }
      }
    }
  }
  &.table-sm {
    th {
      padding: rem(14px) rem(4px);
    }
    td {
      padding: rem(12px) rem(4px);
    }
  }

  &.table-mob {
    @include respond-from(none, $device-sm) {
      thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }
      tbody {
        tr {
          display: flex;
          flex-flow: column;
          padding-bottom: rem(12px);
          border: 1px solid transparent;
          margin-bottom: rem(1px);
          z-index: 999;
          &:first-child {
            border-radius: rem(8px) rem(8px) 0 0;
            td {
              &:first-child {
                &:before {
                  border-radius: rem(8px) rem(8px) 0 0;
                }
              }
            }
          }
          &:last-child {
            border-radius: 0 0 rem(8px) rem(8px);
            td {
              &:last-child {
                &:before {
                  border-radius: 0 0 rem(8px) rem(8px);
                }
              }
            }
          }
        }
        /*
          Label the data
          */
        td {
          border-top: 1px solid transparent;
          padding: rem(12px) rem(16px);
          &.no-label {
            border-top: 0;
          }
          &:first-child:not(.no-label)::before {
            overflow: hidden;
          }
          &:first-child,
          &:last-child {
            padding: rem(12px) rem(16px) !important;
          }
          &:not(.no-label)::before {
            content: attr(data-label);
            display: block;
            padding: rem(8px) rem(16px);
            margin: rem(-13px) rem(-16px) rem(12px);
            background-color: $gray-100;
            color: $gray-600;
            font-size: rem(13px);
            font-weight: 600;
            letter-spacing: 0.08em;
            text-transform: uppercase;
          }
        }
      }

      tbody {
        td {
          &:not(.no-label)::before {
            font-size: 0.625rem;
          }
        }
      }
    }
  }

  .list-sort {
    &:after {
      content: '';
      display: inline-block;
      width: rem(12px);
      height: rem(14px);
      background-image: url('../../images/icon-sort.png');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      margin-left: rem(6px);
      margin-bottom: rem(-2px);
    }
    &-asc {
      &:after {
        background-image: url('../../images/icon-sort-up.png');
        height: rem(8px);
        margin-bottom: 0;
      }
    }
    &-desc {
      &:after {
        background-image: url('../../images/icon-sort-down.png');
        height: rem(8px);
        margin-bottom: 0;
      }
    }
  }
}
