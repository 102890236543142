.btn {
  font-size: $btn-font-size !important;
}

.btn-outline-primary {
  &:hover {
    background-color: lighten($blue, 40%);
    color: $blue;
  }
  &.no-focus {
    &:hover {
      background-color: $white;
    }
    &:focus {
      box-shadow: none;
    }
  }
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-xl {
  min-height: 3.625rem;
  border-radius: $rounded-6;
}

.btn-width-sm {
  min-width: rem(100px);
}

.btn-link {
  padding: 0;
  text-decoration: none;
  &:focus {
    box-shadow: none;
  }
  &-dark {
    padding: 0;
    text-decoration: none;
    color: $black;
    &:focus {
      box-shadow: none;
    }
  }
}

.btn-height-26 {
  height: rem(26px);
}

.btn-warning {
  &.btn {
    background-color: #d5a121;
    border-color: #d5a121;
    color: $white;
    &:hover {
      background-color: #b88d1f;
      border-color: #b88d1f;
      color: $white;
    }
  }
}

.badge-md {
  padding: rem(8px) rem(10px);
}

.btn-icon {
  background: none;
  padding: rem(2px);
  opacity: 0.8;
  img {
    max-width: rem(20px);
  }
  &:hover {
    opacity: 1;
  }
}
