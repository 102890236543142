.login-wrapper{
  width: 100%;
  max-width: 24.75rem;
  margin: 0 auto;
}
.logo-wrap{
  margin-bottom: 1.875rem;
}
.login-heading{
  margin-bottom: 4.5rem;
}
.login-btn-wrap{
  margin-top: 8.125rem;
}
.login-footer{
  min-height: 23.438rem;
}
.resend-btn{
  margin-top: 1.125rem;
}