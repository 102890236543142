html {
  -webkit-font-smoothing: antialiased;
  font-size: 16px;

  @include respond-from($device-md, $device-lg) {
    font-size: 15.5px;
  }

  @include respond-from($device-sm, $device-md) {
    font-size: 15px;
  }

  @include respond-from(none, $device-sm) {
    font-size: 14.5px;
  }
}
