.call-activity{
  &.list-group{
    &.list-group-flush{
      .list-group-item{
        padding: rem(12px) 0;
        border: none;
        &:first-child{
          padding-top: 0;
        }
      }
    }
  }
}