@import '../custom-variables';
.form-control {
  @include placeholder($gray-600, $font-weight-normal);
  font-family: $font-family-base;
  border-color: $gray-300;
  border-radius: $rounded-6;
  &:focus {
    box-shadow: none;
  }
  height: 2.5rem;
  &.is-invalid {
    background-image: none;
  }
}
label {
  margin-bottom: 0.5rem;
}
.hide-number-icon {
  &[type='number']::-webkit-outer-spin-button,
  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
  }
}
.is-invalid-message {
  color: $danger;
  margin-top: 0.625rem;
  margin-bottom: 0;
}

.table-form-control {
  &.form-control {
    width: 8.5rem;
    height: 2.25rem;
    font-size: $font-size-sm;
  }
}

//Custom toggle

/* Rounded sliders */
.custom-toggle-switch {
  position: relative;
  display: inline-block;
  min-width: 92px;
  height: 34px;
  margin-bottom: 0;
  &:before {
    position: absolute;
    content: 'AM';
    height: 30px;
    width: 44px;
    left: 2px;
    bottom: 2px;
    padding: 6px 12px;
  }
  &:after {
    position: absolute;
    content: 'PM';
    height: 30px;
    width: 44px;
    right: 2px;
    bottom: 2px;
    padding: 6px 12px;
  }
  .form-control {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked {
      + {
        .slider {
          &:before {
            content: '';
            -webkit-transform: translateX(44px);
            -ms-transform: translateX(44px);
            transform: translateX(44px);
          }
        }
      }
    }
    &:focus {
      + {
        .slider {
          box-shadow: 0 0 1px #2196f3;
        }
      }
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(118, 118, 128, 0.12);
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: 'AM';
      height: 30px;
      width: 44px;
      left: 2px;
      bottom: 2px;
      padding: 6px 12px;
      background-color: $white;
      -webkit-transition: 0.2s;
      transition: 0.2s;
    }

    &:after {
      position: absolute;
      content: '';
      height: 30px;
      width: 44px;
      right: 2px;
      bottom: 2px;
      padding: 6px 12px;
      background-color: $transparent;
      -webkit-transition: 0.2s;
      transition: 0.2s;
    }
    &.round {
      border-radius: 0.5rem;

      &:before {
        border-radius: 6px;
      }
    }
  }
}

.form-control-sm {
  &.width-sm {
    min-width: 5rem;
    max-height: rem(34px);
  }
}
.min-height-46 {
  min-height: rem(46px);
}

.disable-fields {
  opacity: 40%;
  pointer-events: none;
}

.icon-input {
  position: relative;
  width: rem(350px);
  max-width: 60%;
  .icon {
    position: absolute;
    display: flex;
    top: 0;
    bottom: 0;
    align-items: center;
    left: rem(10px);
    pointer-events: none;
    img {
      max-width: rem(20px);
    }
  }
  .form-control {
    padding-left: rem(34px);
    height: rem(38px);
  }
}
