@keyframes border-blink--success {
  0% {
    border: 1px solid transparent;
  }
  25% {
    border: 1px solid $green;
  }
  75% {
    border: 1px solid $green;
  }
  100% {
    border: 1px solid transparent;
  }
}

@keyframes border-blink--danger {
  0% {
    border: 1px solid transparent;
  }
  25% {
    border: 1px solid $red;
  }
  75% {
    border: 1px solid $red;
  }
  100% {
    border: 1px solid transparent;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
    visibility: visible;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
