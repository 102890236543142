.sidenav-menu {
  .modal-header {
    padding: rem(12px) rem(24px);
    border-bottom: none;
  }
  .modal-body {
    padding: rem(12px) rem(24px);
  }
  .nav-tabs {
    border-bottom: none;
    .nav-item {
      margin: 0;
    }
    .nav-link {
      padding: rem(12px) 0;
      border: none;
      &.active {
        &:after {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .no-mobile-border {
    &.header {
      .header-body {
        border: none;
      }
    }
  }
  .header-btn{
    &.btn{
      width: 100%;
      font-size: rem(13px);
      padding: rem(10px);
    }
  }
}
