.custom-list-dropdown{
  position: absolute;
  top: calc(100% + 4px);
  width: 100%;
  z-index: 1050;
}

.unit-list-group{
  &.list-group{
    &.list-group-flush{
      overflow: auto;
      max-height: rem(190px);
      background-color: $white;
      .list-group-item{
        padding: rem(8px);
        cursor: pointer;
      }
    }

  }
}