.call-log-list {
  margin-bottom: rem(16px);
  .sub-title {
    color: $gray-600;
    font-size: rem(10px);
    margin-bottom: 0.25rem;
  }
  h5 {
    font-size: rem(13px);
    margin-bottom: 0;
  }
  .select-text {
    font-size: rem(14px);
    font-weight: 600;
    line-height: rem(13px);
    letter-spacing: rem(-0.01px);
    text-align: center;
  }
}

.cl-detail {
  &__header {
    display: flex;
    flex-flow: column;
    margin-top: rem(16px);
    margin-bottom: rem(30px);
  }
  &__back {
    padding: rem(10px);
    margin-left: rem(-10px);
    margin-right: rem(16px);
    margin-bottom: rem(12px);
  }
  &__unit {
    display: flex;
    align-items: center;
  }
  &__title {
    p {
      margin-top: rem(-6px);
      margin-bottom: 0;
    }
    h1 {
      font-size: rem(24px);
      font-weight: 600;
      line-height: rem(33px);
      letter-spacing: rem(-0.02px);
      text-align: left;
      margin-bottom: 0;
    }
  }
  &__play {
    margin-left: rem(17px);
    padding-left: rem(17px);
    border-left: 1px solid $black;
  }
  &__label {
    text-align: left;
    font-size: rem(10px);
    font-weight: 600;
    line-height: rem(12px);
    letter-spacing: rem(0.08px);
    color: $gray-600;
    text-transform: uppercase;
    margin-bottom: rem(4px);
  }
  &__text {
    font-size: rem(14px);
    font-weight: 400;
    line-height: rem(20px);
    letter-spacing: rem(-0.01px);
    text-align: left;
    margin-bottom: rem(16px);
  }
  &__loader {
    display: flex;
    padding-top: 100px;
    align-items: center;
    justify-content: center;
  }

  &__info {
    display: flex;
    margin-bottom: rem(14px);
    &-item {
      flex: 1 1 auto;
      margin-right: rem(8px);
      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &__play {
    padding-top: rem(2px);
  }

  &__desc {
    margin-bottom: rem(40px);
    &-text {
      margin-bottom: rem(10px);
    }
  }
  &__action {
    .btn {
      margin-bottom: rem(15px);
    }
    .helper-text {
      color: $text-muted;
      font-size: rem(10px);
      font-weight: 400;
      line-height: rem(15px);
      letter-spacing: rem(-0.01px);
      text-align: center;
      margin-top: rem(-8px);
      margin-bottom: rem(15px);
    }
  }

  @include respond-from($device-sm, none) {
    &__header {
      flex-flow: row;
    }
    &__back {
      margin-bottom: 0;
    }
    &__info {
      &-item {
        flex: 1;
      }
    }
  }
}

.call-details-card {
  &.card {
    margin-bottom: rem(12px);
    border: 1px solid $card-border;
    .card-body {
      padding: rem(16px);
      border-radius: rem(8px);
    }
  }
}
.call-details-badge-group {
  margin-bottom: rem(16px);
}
.detail-header {
  padding: rem(10px);
  i {
    margin-right: rem(16px);
    font-size: 10px;
  }
  a {
    font-weight: 500;
    font-size: rem(20px);
    line-height: rem(24px);
  }
}

.audio-waves {
  display: none;
  .audio-icon {
    font-size: 33px !important;
  }
}
.audioPlayer-timeline {
  max-width: rem(350px);
  min-width: rem(300px);
  .audio-details {
    background-color: $primary;
    border-radius: rem(54px);
    padding: 10px 22px 10px 10px;
    img {
      height: rem(33px);
      width: auto;
    }
    p {
      padding-top: rem(4px);
      text-align: right;
      min-width: rem(32px);
    }
  }
  .audio-icon-only {
    display: none;
  }
  .audio-waves {
    display: block;
  }
}

.call-activity-item {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    border-left: 1px dotted $gray-300;
    height: 100%;
    left: rem(16px);
    top: rem(30px);
  }
  .activity-icon {
    position: relative;
    width: rem(32px);
    height: rem(32px);
    min-width: rem(32px);
    background-color: $gray-300;
    border-radius: 50%;
    i {
      font-size: $font-size-base;
      @include perfect-center;
    }
  }
  .activity-time {
    font-size: $font-size-xs;
    color: $text-muted;
  }
}
.call-activity {
  &.list-group {
    .list-group-item {
      &:last-child {
        .call-activity-item {
          &:after {
            display: none;
          }
        }
      }
    }
  }
}

.unit-error-icon {
  padding-top: 3px;
}

@media (max-width: 400px) {
  .audioPlayer-timeline {
    min-width: rem(255px);
    .audio-details {
      padding: 4px 22px 4px 4px;
      p {
        font-size: rem(14px);
      }
    }
  }
}

.empty-list {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: rem(40px) rem(20px);
  img {
    width: auto;
    max-width: 75%;
    margin-bottom: rem(40px);
  }
  figcaption {
    font-size: rem(22px);
    line-height: rem(29px);
    letter-spacing: rem(-0.01px);
    text-align: left;
    color: #1f2d3d;
    text-align: center;
  }
  @include respond-from(none, $device-sm) {
    figcaption {
      font-size: rem(18px);
    }
  }
}

.list-filters {
  display: flex;
  align-items: center;
  .btn {
    padding-left: 0;
    padding-right: 0;
  }

  @include respond-from(none, $device-sm) {
    flex-direction: column;
    align-items: flex-start;
    .msl-input {
      margin-left: 0;
      margin-right: 0;
      padding-left: 5px;
    }
  }
}
