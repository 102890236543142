.save-message{
  //min-width: 120px;
  width: rem(56px);
}

.line-height36{
  line-height: rem(36px);
}

.custom-accordion{
  .cs-header{
    .cs-day{
      font-size: rem(20px);
      line-height: rem(24px);
      font-weight: $font-weight-bold;
    }
    .cs-hours{
      font-size: rem(13px);
      line-height: rem(19px);
      font-weight: $font-weight-normal;
      .title{
        color: $text-muted;
        margin-right: rem(8px);
      }
    }
  }
  &.accordion {
    margin-bottom: rem(10px);
    .accordion-button {
      .toggle-arrow {
        position: absolute;
        right: rem(16px);
        top: 50%;
        transform: translateY(-50%) rotate(270deg);
      }
      &:after {
        display: none;
      }
      :not(.collapsed) {
        color: $dark;
      }
      &.collapsed{
        .toggle-arrow{
          transform: rotate(90deg) translate(-50%, 0%);
        }
      }
    }
    .accordion-body {
      padding-top: 0;
    }
  }
}