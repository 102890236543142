.btn-edit {
  min-width: 3.75rem;
  img {
    width: 1.25rem;
    height: auto;
  }
}
.users-property-list {
  border: 0.0625rem solid $gray-300;
  border-radius: 0.375rem;
  max-width: 20rem;
  width: 100%;
  max-height: 2rem;
  overflow: hidden;
  &.btn {
    width: fit-content;
    height: fit-content;
  }
  .badge {
    margin: 0.125rem;
  }
}

.after-comma {
  .max-width-200 {
    max-width: 12.5rem;
  }
  span {
    &:after {
      content: ',';
    }
    &:nth-last-child(1) {
      &:after {
        content: none;
      }
    }
  }
  &:hover {
    background-color: $white;
    padding: 0.75rem;
    border: 0.0625rem solid $gray-300;
    border-radius: 0.375rem;
    position: absolute;
    top: 0.25rem;
    left: 0;
    box-shadow: 0 0.5rem 1.5rem rgba($black, 0.16);
    z-index: 1;
    .text-truncate {
      overflow: visible;
      text-overflow: initial;
      white-space: initial;
    }
    .max-width-200 {
      width: 15rem;
    }
  }
}

.mobile-tablet {
  .new-user-btn {
    width: 100%;
  }
}

.userob-accordion {
  .name {
    font-size: rem(20px);
    line-height: rem(24px);
    font-weight: $font-weight-bold;
  }
  .role {
    font-size: rem(13px);
    line-height: rem(13px);
    font-weight: $font-weight-medium;
    margin-bottom: rem(10px);
  }
  .sub-text {
    font-size: rem(11px);
    text-transform: uppercase;
    color: $text-muted;
    margin-bottom: rem(5px);
  }
  .normal {
    font-size: rem(13px);
    line-height: rem(13px);
    font-weight: $font-weight-normal;
    margin-bottom: rem(5px);
  }
  .edit-delete-btn {
    width: 45%;
  }
}

.userob-mobile-modal-footer {
  .edit-delete-btn {
    width: 46%;
  }
}

.notification-modal {
  .modal-header {
    border: 0;
    padding: rem(25px) rem(40px) 0;
    margin-bottom: 5px;
  }
  .modal-body {
    padding-top: rem(15px);
  }
  .table {
    tr {
      border: 0;
    }

    th {
      background: none;
      color: $black;
      font-size: rem(13px);
      font-weight: 500;
      line-height: rem(16px);
      letter-spacing: rem(-0.01px);
      text-transform: none;
    }
    td {
      font-size: rem(12px);
      font-weight: 400;
      line-height: rem(14px);
      letter-spacing: rem(-0.01px);
      text-align: left;
    }

    th,
    td {
      padding: rem(10px) rem(15px);
      &:nth-last-of-type(1),
      &:nth-last-of-type(2) {
        text-align: center;
      }
    }
  }
}
