.custom-dropdown {
  &.dropdown {
    .dropdown-toggle {
      color: $black;
      &.font-size-28 {
        letter-spacing: -0.02em;
      }
      &:after {
        color: $gray-dark-02;
      }
      &.outline-toggle {
        padding: 0.625rem 0.75rem;
        width: 100%;
        text-align: left;
        color: $gray-600;
        font-weight: normal;
        border: 0.0625rem solid $gray-300;
        &:after {
          right: 0.75rem;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      &.toggle-sm {
        padding: 0.375rem 0.75rem;
        position: relative;
        height: 2.125rem;
        &:after {
          right: 0.5rem;
        }
      }
    }
    .dropdown-menu {
      border: 0.0625rem solid $gray-300;
      box-shadow: 0 0.0625rem 0.5rem rgba($box-shadow, 0.12);
      border-radius: 0.625rem;
      padding: 0;
      max-height: 12.5rem;
      overflow: auto;
      .dropdown-item {
        color: $gray-900;
        padding: 0.625rem 1rem;
      }
      &.full-width {
        width: 100%;
      }
    }
  }
  &.is-invalid .dropdown-toggle.outline-toggle {
    border: 1px solid $red;
  }
}

.custom-multiple-select {
  .msl-chip {
    font-size: rem(14px);
  }
  .msl-option {
    font-size: rem(14px);
  }
  .msl-input {
    font-size: rem(14px);
  }
  &.msl-wrp {
    width: 100%;
    .msl {
      border: 0.0625rem solid $gray-300;
      .msl-input {
        &:before {
          color: $gray-600;
        }
      }
    }
    .msl-arrow-icn {
      width: 15px;
      height: 15px;
    }
    .msl-chip {
      background: $light-blue-01;
      color: $white;
      .msl-chip-delete {
        color: $white;
        background: transparent;
      }
    }
    .msl-option {
      padding-left: 40px;
      position: relative;
      background: $white;
      color: $gray-900;
      &:before {
        content: '';
        position: absolute;
        top: 0.625rem;
        width: 14px;
        background: $gray-400;
        left: 12px;
        height: 14px;
        border-radius: 6px;
      }
      &.msl-option-active {
        background: $white;
        color: $gray-900;
        &:before {
          background: $primary;
        }
        &:after {
          content: '';
          position: absolute;
          width: 8px;
          border-left: 2px solid $white;
          border-bottom: 2px solid $white;
          left: 15px;
          top: 13px;
          height: 5px;
          transform: rotate(-45deg);
        }
      }
    }
  }
}
.no-arrow {
  .dropdown-toggle {
    &:after {
      display: none;
    }
  }
}

.custom-table-dropdown {
  &.show {
    .dropdown-toggle {
      border: $gray-200 solid 1px;
      background: transparent;
      color: $black;
      box-shadow: none;
      &:focus {
        box-shadow: none;
      }
    }
  }
  &.has-error {
    .dropdown-toggle {
      border-color: $red;
      color: $red;
    }
  }
  .dropdown-toggle {
    padding-right: rem(18px);
    position: relative;
    width: 100%;
    max-width: rem(180px);
    text-align: left;
    border: $gray-200 solid 1px;
    background: transparent;
    color: $black;
    min-width: rem(140px);
    box-shadow: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: rem(4px);
    }
  }
  .dropdown-menu {
    width: 100%;
    max-width: rem(180px);
    padding: 0;
    border-color: $gray-300;
    max-height: rem(160px);
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: rem(10px);
    .dropdown-item {
      padding: rem(10px) rem(16px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

@media (max-width: 991px) {
  .custom-table-dropdown {
    .dropdown-toggle {
      max-width: none;
    }
    .dropdown-menu {
      max-width: none;
    }
  }
}

.select-box {
  button {
    background-color: transparent;
    border: 0;
    color: $text-muted;
  }
}
.date-filter {
  position: relative;
  .dropdown-toggle {
    cursor: auto;
  }
  .date-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 0;
    right: 0;
    top: rem(-6px);
    bottom: rem(-6px);
    color: #8492a6;
    background-color: $gray-light;
    &:after {
      cursor: pointer;
      opacity: 0.65;
    }
  }
  .reset-date {
    cursor: pointer;
    position: absolute;
    right: rem(34px);
    top: rem(7px);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.7;
    img {
      max-width: rem(12px);
    }
  }
  .rmdp-container {
    input {
      background-color: transparent;
      border: 0;
      color: $text-muted;
      max-width: rem(160px);
      text-indent: -9999px;
    }
  }
  @include respond-from(none, $device-sm) {
    .date-select {
      margin-left: rem(5px);
    }
  }
}

.multi-select-filter {
  --font-size: #{rem(14px)};
  --input-width: 180px;
  margin-right: rem(12px);
  .msl {
    color: #8492a6;
    background-color: #f9fbfd;
    border: none;
  }
  .msl-clear-btn {
    svg {
      width: rem(8px);
      height: rem(8px);
    }
  }
  .msl-arrow-btn {
    svg {
      width: rem(12px);
      height: rem(12px);
    }
  }
  .msl-arrow-btn:hover,
  .msl-clear-btn:hover {
    background: none;
  }
}

.custom-filter-dropdown {
  &.show {
    .dropdown-toggle {
      border: $gray-200 solid 1px;
      background: transparent;
      color: $black;
      box-shadow: none;
    }
  }
  &.has-error {
    .dropdown-toggle {
      border-color: $red;
      color: $red;
    }
  }
  .dropdown-toggle {
    padding-right: rem(18px);
    position: relative;
    width: 100%;
    max-width: rem(180px);
    text-align: left;
    border: none;
    background: transparent;
    color: #8492a6;
    min-width: rem(140px);
    box-shadow: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: rem(4px);
    }
  }
  .dropdown-menu {
    width: 100%;
    max-width: rem(180px);
    padding: 0;
    border-color: $gray-300;
    max-height: rem(160px);
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: rem(10px);
    .dropdown-item {
      padding: rem(10px) rem(16px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}