.header-nav {
  background: $white;
  margin-bottom: 0;
  .navbar {
    padding: 0;
    box-shadow: 0px 1px 0px #edf2f9;
    border-bottom: 0;
    .container {
      padding-left: rem(16px);
      padding-right: rem(16px);
    }
  }
  .navbar-brand {
    max-width: rem(100px);
  }
  .navbar-links {
    max-width: calc(100% - rem(130px));
    overflow-x: auto;
    overflow-y: hidden;
  }
  .logo img {
    max-width: 100%;
  }
  .nav-tabs {
    border-bottom: 0;
    flex-wrap: nowrap;
    .nav-item {
      margin-left: 0;
      margin-right: rem(25px);
      &:last-of-type {
        margin-right: 0;
      }
    }
    .nav-link {
      position: relative;
      display: block;
      font-size: rem(15px);
      line-height: rem(21px);
      padding-top: rem(16px);
      padding-bottom: rem(16px);
      white-space: nowrap;
      border-bottom: 1px solid transparent;
      &.active {
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: $blue;
        }
      }
    }
  }
  @include respond-from(none, $device-sm) {
    .navbar-links {
      max-width: calc(100% - rem(55px));
    }
  }
}
