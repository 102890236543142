.custom-tabs{
  .tab-content{
    padding-top: rem(24px);
  }
}

@media (max-width: 991px) {
  .custom-tabs{
    .nav {
      &.nav-tabs{
        width: -moz-fit-content;
        width: fit-content;
        margin: rem(32px) auto 0;
        background-color: $white;
        border-radius: rem(4px);
        border-bottom: none;
        .nav-item{
          margin: 0;
          .nav-link{
            padding: rem(5px) rem(24px);
            border-radius: rem(4px);
            color: $primary;
            .badge-light{
              background-color: $primary;
              color: $white;
            }
            &.active{
              background-color: $primary;
              color: $white;
              .badge-light{
                background-color: $white;
                color: $primary;
              }
            }
          }
        }
      }
    }
  }
}