//font family
$path-to-fonts: '../../fonts';
$public-sans: 'Public Sans';

//font-weight
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semi-bold: 500 !default;
$font-weight-bold: 700 !default;

//font size
$font-size-base: 0.9375rem !default; // 15px
$font-size-sm: 0.813rem;
$font-size-xs: 0.75rem;
$font-size-xxs: 0.625rem;
$h1-font-size: 1.875rem;
$font-size-lg: 1.133333333334rem;
$font-size-xl: 1.5rem;

// device widths
$device-xs: 480px !default;
$device-sm: 768px !default;
$device-md: 992px !default;
$device-lg: 1200px !default;
$device-xl: 1600px !default;

//border-radius
$rounded-sm: 0.125rem;
$rounded-6: 0.375rem;

//overriding bootstrap variables

$font-family-base: $public-sans !default;
$container-padding-x: 1rem;
$headings-font-weight: $font-weight-semi-bold;
$headings-line-height: 1.2;
$font-size-xl: 1.5rem;

$gray-light: #f9fbfd;
$gray-light100: #f9fafc;
$gray-dark-02: #636366;
$box-shadow: #1f2d3d;
$transparent: transparent;
$btn-font-size: rem(13px);

//dashkit
$white: #ffffff !default;
$gray-100: #f9fbfd !default;
$gray-200: #edf2f9 !default;
$gray-300: #e3ebf6 !default;
$gray-400: #d2ddec !default;
$gray-500: #b1c2d9 !default;
$gray-600: #95aac9 !default;
$gray-700: #6e84a3 !default;
$gray-800: #3b506c !default;
$gray-900: #283e59 !default;
$black: #12263f !default;

$gray-600-dark: #244166 !default;
$gray-700-dark: #1e3a5c !default;
$gray-800-dark: #152e4d !default;
$gray-900-dark: #132a46 !default;
$black-dark: #12263f !default;

$blue: #2c7be5 !default;
$indigo: #727cf5 !default; // NOT USED
$purple: #6b5eae !default;
$pink: #ff679b !default; // NOT USED
$red: #e63757 !default;
$orange: #fd7e14 !default; // NOT USED
$yellow: #f6c343 !default;
$green: #00d97e !default;
$teal: #02a8b5 !default; // NOT USED
$cyan: #39afd1 !default;

$primary: $blue !default;
$secondary: $gray-700 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-200 !default;
$dark: $black !default;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
    'white': $white,
  ),
  $theme-colors
);
// stylelint-enable

$lighter: $gray-100 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-900 !default;
$yiq-text-light: $white !default;
// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-validation-icons: false !default;

$body-bg: $white;
$body-color: $black;

//Custom Variables

$light-blue-01: #33a3d3;
$light-red-01: #ffe1cc;
$light-red-02: #ee6a63;
$card-border: #dee1e4;
$text-muted: #8492a6;

$unit-classes: (
  1: rem(3px),
  2: rem(6px),
  3: rem(12px),
  4: rem(16px),
  5: rem(24px),
  6: rem(30px),
  10: rem(10px),
  20: rem(20px),
  30: rem(30px),
);
