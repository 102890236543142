@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon/icomoon.eot');
  src: url('../fonts/icomoon/icomoon.eot') format('embedded-opentype'),
    url('../fonts/icomoon/icomoon.ttf') format('truetype'),
    url('../fonts/icomoon/icomoon.woff') format('woff'),
    url('../fonts/icomoon/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-caution:before {
  content: '\e900';
}
.icon-cross:before {
  content: '\e901';
}
.icon-chevron-right:before {
  content: '\e902';
}
.icon-chevron-left:before {
  content: '\e903';
}
.icon-chevron-up:before {
  content: '\e90c';
}
.icon-chevron-down:before {
  content: '\e90d';
}
.icon-pause-circle:before {
  content: '\e904';
}
.icon-play-circle:before {
  content: '\e905';
}
.icon-hamburger:before {
  content: '\e906';
}
.icon-checkmark:before {
  content: '\e907';
}
.icon-speaker:before {
  content: '\e90a';
}
.icon-Phone:before {
  content: '\e90b';
}
.icon-rotate-ccw:before {
  content: '\e90f';
}
.icon-check-circle:before {
  content: '\e910';
}
.icon-phone-outgoing:before {
  content: '\e911';
}
.icon-phone-incoming:before {
  content: '\e908';
}
.icon-phone-missed:before {
  content: '\e909';
}
.icon-log-in:before {
  content: '\e90e';
}
.icon-edit:before {
  content: '\e913';
}
.icon-eye:before {
  content: '\e912';
}
.icon-sync:before {
  content: '\e914';
}
.icon-more-vertical:before {
  content: '\e915';
}
