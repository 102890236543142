@mixin placeholder($color, $font-weight) {
  &::-webkit-input-placeholder {
    color: $color;
    opacity: 1;
    font-weight: $font-weight;
  }
  &::-moz-placeholder {
    color: $color;
    opacity: 1;
    font-weight: $font-weight;
  }
  &:-ms-input-placeholder {
    color: $color;
    opacity: 1;
    font-weight: $font-weight;
  }
  &:-moz-placeholder {
    color: $color;
    opacity: 1;
    font-weight: $font-weight;
  }
}

@mixin perfect-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin respond-from($min, $max) {
  @if $min==none {
    @media (max-width: ($max - 1)) {
      @content;
    }
  } @else if $max==none {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @media (min-width: $min) and (max-width: ($max - 1)) {
      @content;
    }
  }
}

@function rem($value) {
  @return #{$value/16px}rem;
}
