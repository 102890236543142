.custom-modal {
  &.no-border {
    &.delete-modal {
      .modal-dialog {
        max-width: rem(500px);
      }
      .modal-body {
        padding: rem(24px) rem(24px) rem(18px);
        .action-undone {
          color: $text-muted;
        }
      }
      .modal-footer {
        .btn-outline-light {
          border: none;
          outline: none;
          &:hover {
            background-color: $transparent;
          }
        }
      }
    }
    .modal-header {
      border-bottom: none;
    }
    .modal-footer {
      border-top: none;
    }
  }
  .modal-content {
    border-radius: 12px;
  }
  &.custom-drawer {
    top: 0;
    left: 0;
    &.modal {
      overflow: unset;
      &.fade {
        .modal-dialog {
          transform: translate(-100%, 0);
        }
      }
      &.show {
        .modal-dialog {
          transform: none;
        }
      }
    }
    .modal-dialog {
      margin: 0;
    }
    .modal-content {
      background-color: $white;
      width: 100%;
      max-width: rem(300px);
      border: none;
      border-radius: 0;
      min-height: 100vh;
      height: 100%;
    }
    .close {
      opacity: 1;
    }
    .modal-body {
      padding: rem(38px) rem(24px);
      height: auto;
      overflow: auto;
    }
  }
}

@media (max-width: 992px) {
  .custom-modal {
    &.no-border {
      &.delete-modal {
        .modal-body {
          .action-undone {
            color: $text-muted;
          }
        }
        .modal-footer {
          justify-content: space-between;
          .btn {
            width: calc(50% - 8px);
            padding: rem(10px);
            font-size: rem(13px) !important;
            &.btn-outline-light {
              border: 1px solid $gray-200;
              outline: none;
              &:hover {
                background-color: $transparent;
              }
            }
          }
        }
      }
    }
  }
}
