.custom-tooltip{
    &.tooltip{
    &.with-list{
            width:  rem(320px);
        .tooltip-inner{
            padding: 4px;
            max-width: 100%;
            width: fit-content;
            margin: auto;
        }
    }
    width: rem(200px);
        &.no-tip-arrow{
            .tooltip-arrow{
                display: none;
            }
        }
        .tooltip-arrow{
            &:before{
//                 bottom: -1px;
                width: rem(12px);
                height: rem(12px);
                background-color: $white;
                transform: rotate(45deg);
                top: 0;
                border-left: 1px solid $gray-300;
                border-top: 1px solid $gray-300;
                border-right: none;
                border-bottom: none;
            }
        }
        .tooltip-inner{
            background-color: $white;
            font-family: $font-family-base;
            font-size: rem(12px);
            border: 1px solid $gray-300;
        }
    }
}