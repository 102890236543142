body {
  background-color: $white;
  font-family: $font-family-base;
}
//font size
.font-size-xs {
  font-size: $font-size-xs !important;
}

.font-size-sm {
  font-size: $font-size-sm !important;
}

.font-size-xl {
  font-size: $font-size-xl !important;
}

.font-size-lg {
  font-size: $font-size-lg !important;
}

.font-size-15 {
  font-size: 0.9375rem !important;
}
.font-size-20 {
  font-size: 1.25rem !important;
}

.font-size-28 {
  font-size: 1.75rem !important;
}
.font-size-36 {
  font-size: 2.25rem !important;
}

// font weight
.font-weight-medium {
  font-weight: $font-weight-medium !important;
}

.font-weight-bold {
  font-weight: $font-weight-bold !important;
}

.font-weight-normal {
  font-weight: $font-weight-normal !important;
}

.pointer-event-none {
  pointer-events: none;
}

//Margin

@each $margin, $val in $unit-classes {
  .mt-#{$margin} {
    margin-top: $val !important;
  }
  .mb-#{$margin} {
    margin-bottom: $val !important;
  }
  .ms-#{$margin} {
    margin-left: $val !important;
  }
  .me-#{$margin} {
    margin-right: $val !important;
  }
}

.goto-link {
  color: $body-color;
  text-decoration: none;
  &:hover {
    color: $body-color;
  }
}
//text color
.sub-text {
  color: $gray-600 !important;
}

.dark-text {
  color: $box-shadow !important;
}

.text-muted {
  color: $text-muted !important;
}

.cursor-pointer {
  cursor: pointer;
}

.container {
  padding-left: $container-padding-x;
  padding-right: $container-padding-x;
}

.heading1 {
  font-size: 2rem;
  line-height: 2.25rem;
}

a {
  text-decoration: none;
}

.line-height-0 {
  line-height: 0;
}

.line-height-1 {
  line-height: 1;
}

.main-content {
  background-color: $gray-light;
  padding-top: 3.875rem;
  min-height: 100vh;
  padding-bottom: 1.5rem;
}

.loader-wrap {
  left: 0;
  background-color: rgba($black, 0.25);
  z-index: 99999;
}

.card {
  overflow: hidden;
}

.getting-started {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 9999;
  &.fade-out {
    animation: fade-out 0.5s ease-in;
    visibility: hidden;
    opacity: 0;
  }
}
.has-left-icon {
  position: relative;
  padding-left: rem(24px);
  .left-icon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

.has-icon-only {
  position: relative;
  width: rem(30px);
  height: rem(30px);
  i {
    @include perfect-center;
  }
}

.property-switcher {
  font-size: 1.75rem;
  line-height: 1;
  max-width: 100%;
  width: fit-content;
  width: -moz-fit-content;
}

.toast-container.nortify {
  padding: map-get($unit-classes, 3);
  position: fixed !important;
  top: calc(90vh - 100px) !important;
  bottom: auto !important;
  z-index: 1100;
}

.list-header {
  display: flex;
  align-items: flex-start;
  .header-btn {
    margin-right: rem(15px);
  }
  @include respond-from(none, $device-md) {
    flex-flow: column;
    .header-btn {
      margin-right: 0;
      margin-bottom: rem(20px);
    }
    .icon-input {
      width: 100%;
      max-width: none;
    }
  }
}
